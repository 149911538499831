import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import { captureException } from "@sentry/nextjs"

import axios from "axios"

const initialState = {
    status: "idle",
    errorMessage: "",
    showGetInTouchModal: false,
    recipient: "",
}

export const sendGetInTouchData = createAsyncThunk(
    "getInTouchModal/sendData",
    async (values, { rejectWithValue }) => {
        values.key = process.env.API_SECRET
        try {
            const response = await axios.post(
                `${process.env.API_BASE_URL}user-forms/get-in-touch/`,
                values,
            )
            if (response.status === 200) {
                return response.data
            } else {
                captureException(response.status)
                return rejectWithValue(response.status)
            }
        } catch (err) {
            captureException(err)
            return rejectWithValue(err.message)
        }
    },
)

const getInTouchModalSlice = createSlice({
    name: "getInTouchModal",
    initialState,
    reducers: {
        toggleGetInTouchModal: (state, action) => {
            state.showGetInTouchModal = action.payload
            state.recipient = ""
        },
        setGetInTouchRecipient: (state, action) => {
            state.recipient = action.payload
        },
        resetGetInTouchModal: (state) => {
            state.status = "idle"
        },
    },

    extraReducers(builder) {
        builder
            .addCase(sendGetInTouchData.pending, (state) => {
                state.status = "loading"
            })
            .addCase(sendGetInTouchData.fulfilled, (state) => {
                Object.assign(state, initialState)
                state.status = "success"
            })
            .addCase(sendGetInTouchData.rejected, (state, action) => {
                state.status = "error"
                state.errorMessage = action.payload || "No response"
            })
    },
})

export const {
    toggleGetInTouchModal,
    setGetInTouchRecipient,
    resetGetInTouchModal,
} = getInTouchModalSlice.actions
export default getInTouchModalSlice.reducer

import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import { captureException } from "@sentry/nextjs"

import axios from "axios"

const initialState = {
    status: "idle",
    errorMessage: "",
    property: {
        property_ref: "",
        display_address: "",
        features: [],
        images: [],
        floorplans: [],
        documents: [],
        virtual_tours: [],
        comm_class_orders: [],
    },
}

export const getPropertyData = createAsyncThunk(
    "property/getData",
    async (getPropertyRef, { fulfillWithValue, rejectWithValue }) => {
        try {
            const response = await axios.get(
                `${process.env.API_BASE_URL}property/${getPropertyRef}`,
            )

            if (response.status != 200) {
                return rejectWithValue(response.status)
            }
            return fulfillWithValue(response.data)
        } catch (err) {
            captureException(err)
            return rejectWithValue(err.message)
        }
    },
)

const getPropertySlice = createSlice({
    name: "getProperty",
    initialState,

    extraReducers(builder) {
        builder
            .addCase(getPropertyData.pending, (state) => {
                Object.assign(state, initialState)
                state.status = "loading"
            })
            .addCase(getPropertyData.fulfilled, (state, action) => {
                Object.assign(state, initialState)
                state.status = "success"
                state.property = action.payload
            })
            .addCase(getPropertyData.rejected, (state, action) => {
                Object.assign(state, initialState)
                state.status = "error"
                state.errorMessage = action.payload || "No response"
            })
    },
})

export default getPropertySlice.reducer

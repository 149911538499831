import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import { captureException } from "@sentry/nextjs"

import axios from "axios"

const initialState = {
    status: "idle",
    errorMessage: "",
    properties: [],
    count: 0,
    next: 0,
    prev: 0,
}

export const listPropertyData = createAsyncThunk(
    "property/listData",
    async (listPropertyParams, { fulfillWithValue, rejectWithValue }) => {
        try {
            const response = await axios.get(
                `${process.env.API_BASE_URL}property/${listPropertyParams}`,
            )

            if (response.status != 200) {
                return rejectWithValue(response.status)
            }
            return fulfillWithValue(response.data)
        } catch (err) {
            captureException(err)
            throw rejectWithValue(err.message)
        }
    },
)

const listPropertiesSlice = createSlice({
    name: "listProperty",
    initialState,

    extraReducers(builder) {
        builder
            .addCase(listPropertyData.pending, (state) => {
                Object.assign(state, initialState)
                state.status = "loading"
            })
            .addCase(listPropertyData.fulfilled, (state, action) => {
                Object.assign(state, initialState)
                state.status = "success"
                state.properties = action.payload.results
                state.count = action.payload.count
                state.next = action.payload.next
                state.prev = action.payload.previous
            })
            .addCase(listPropertyData.rejected, (state, action) => {
                state.status = "error"
                state.errorMessage = action.payload || "No response"
            })
    },
})

export default listPropertiesSlice.reducer

import React from "react"
import Link from "next/link"
import Image from "next/image"
import { useRouter } from "next/router"

import { useDispatch } from "react-redux"

import { toggleGetInTouchModal } from "@/slices/getInTouchModalSlice"

import { Nav, Container } from "react-bootstrap"

function SubHeader({ expanded, setExpanded }) {
    const dispatch = useDispatch()

    const router = useRouter()

    const scrollToTop = () => {
        if (typeof window !== "undefined") {
            window.scrollTo(0, 0)
        }
    }

    return (
        <Container
            fluid
            className="mb-0 text-semi-bold py-2"
            style={{
                backgroundColor: "#f2f2f2",
            }}
        >
            {/* Top Navbar */}
            <Container fluid="xxl" className="d-flex p-0 mx-auto h-100">
                <Nav activeKey={router.pathname} as="ul" className="topnav">
                    <Nav.Item
                        as="li"
                        className="mx-3 d-none d-md-inline my-auto"
                    >
                        <Nav.Link
                            as={Link}
                            href="/our-team"
                            eventKey="/our-team"
                            className="pb-0 mb-1 px-1"
                        >
                            <a
                                onClick={() => {
                                    scrollToTop()
                                    setExpanded(false)
                                }}
                            >
                                <span
                                    className={`text-semi-bold ${
                                        router.pathname === "/our-team"
                                            ? "active-h-link"
                                            : ""
                                    }`}
                                >
                                    Our team
                                </span>
                            </a>
                        </Nav.Link>
                    </Nav.Item>

                    <Nav.Item as="li" className="mx-3 d-inline my-auto">
                        <Nav.Link
                            as={Link}
                            href="/contact"
                            eventKey="/contact"
                            className="pb-0 mb-1 px-1"
                        >
                            <a
                                onClick={() => {
                                    scrollToTop()
                                    setExpanded(false)
                                }}
                            >
                                <span
                                    className={`text-semi-bold ${
                                        router.pathname === "/contact"
                                            ? "active-h-link"
                                            : ""
                                    }`}
                                >
                                    Contact
                                </span>
                            </a>
                        </Nav.Link>
                    </Nav.Item>

                    <Nav.Item
                        as="li"
                        className="mx-3 d-none d-lg-inline d-xxl-none my-auto"
                    >
                        <Nav.Link
                            as={Link}
                            href="/buyers"
                            eventKey="/buyers"
                            className="pb-0 mb-1 px-1"
                        >
                            <a onClick={scrollToTop()}>
                                <span
                                    className={`text-semi-bold ${
                                        router.pathname === "/buyers"
                                            ? "active-h-link"
                                            : ""
                                    }`}
                                >
                                    Buyers
                                </span>
                            </a>
                        </Nav.Link>
                    </Nav.Item>

                    <Nav.Item
                        as="li"
                        className="d-none d-lg-inline d-xxl-none my-auto mx-3"
                    >
                        <Nav.Link
                            as={Link}
                            href="/tenants"
                            eventKey="/tenants"
                            className="pb-0 mb-1"
                        >
                            <a onClick={scrollToTop()}>
                                <span
                                    className={`text-semi-bold ${
                                        router.pathname === "/tenants"
                                            ? "active-h-link"
                                            : ""
                                    }`}
                                >
                                    Tenants
                                </span>
                            </a>
                        </Nav.Link>
                    </Nav.Item>
                </Nav>
                <a
                    href="#"
                    className="ms-auto p-0 px-1 my-auto d-flex"
                    onClick={(e) => {
                        e.preventDefault()
                        dispatch(toggleGetInTouchModal(true))
                    }}
                >
                    <Image
                        src="https://webbjenkinsstatic.imgix.net/icons/email.svg"
                        alt="Email icon"
                        height={20}
                        width={16}
                        className="my-auto"
                    />

                    <span className="text-semi-bold ms-2 d-none d-xl-inline m-0">
                        propertyservices@webbjenkins.co.uk
                    </span>
                </a>
                <a href="tel:01983 856111" className="ms-4 px-1 my-auto d-flex">
                    <Image
                        src="https://webbjenkinsstatic.imgix.net/icons/phone.svg"
                        alt="Phone icon"
                        height={20}
                        width={16}
                        className="my-auto"
                    />

                    <span className="text-semi-bold my-auto ms-2 d-none d-md-inline">
                        01983 856111
                    </span>
                </a>

                <a
                    href="https://www.facebook.com/WebbJenkinsIOW/"
                    className="ms-4 p-1 my-auto d-none d-md-flex"
                    role="button"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    <Image
                        src="https://webbjenkinsstatic.imgix.net/icons/facebook-f-brands-orange.svg"
                        alt="Facebook logo"
                        height={18}
                        width={11}
                        className="my-auto"
                    />
                </a>

                <a
                    href="https://twitter.com/WebbJenkinsIOW/"
                    className="ms-4 p-1 my-auto d-none d-md-flex"
                    role="button"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    <Image
                        src="https://webbjenkinsstatic.imgix.net/icons/twitter-brands-orange.svg"
                        alt="Twitter logo"
                        height={20}
                        width={20}
                        className="my-auto"
                    />
                </a>

                <a
                    href="https://www.instagram.com/WebbJenkinsIOW/"
                    className="ms-4 p-1 my-auto d-none d-md-flex"
                    role="button"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    <Image
                        src="https://webbjenkinsstatic.imgix.net/icons/instagram-brands-orange.svg"
                        alt="Twitter logo"
                        height={20}
                        width={20}
                        className="my-auto"
                    />
                </a>
            </Container>
            {/* Top Navbar End */}
        </Container>
    )
}

export default SubHeader

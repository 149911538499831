import "../styles/scss/soft-design-system-pro.scss"
import SSRProvider from "react-bootstrap/SSRProvider"

import store from "@/redux/store"
import { Provider } from "react-redux"

import WebsiteLayout from "@/layouts/websiteLayout"

function MyApp({ Component, pageProps }) {
    const getLayout =
        Component.getLayout || ((page) => <WebsiteLayout>{page}</WebsiteLayout>)
    return (
        <>
            <SSRProvider>
                <Provider store={store}>
                    {getLayout(<Component {...pageProps} />)}
                </Provider>
            </SSRProvider>
        </>
    )
}

export default MyApp

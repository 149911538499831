import { configureStore } from "@reduxjs/toolkit"

import getInTouchModalReducer from "@/slices/getInTouchModalSlice"
import valuationModalReducer from "@/slices/valuationModalSlice"
import findHomeModalReducer from "@/slices/findHomeModalSlice"
import makeEnquiryModalReducer from "@/slices/makeEnquiryModalSlice"
import makeEnquiryFormReducer from "@/slices/makeEnquiryFormSlice"
import requestViewingModalReducer from "@/slices/requestViewingModalSlice"
import listPropertiesReducer from "@/slices/listPropertiesSlice"
import getPropertyReducer from "@/slices/getPropertySlice"
import cookieReducer from "@/slices/cookieSlice"

const store = configureStore({
    reducer: {
        getInTouchModal: getInTouchModalReducer,
        valuationModal: valuationModalReducer,
        findHomeModal: findHomeModalReducer,
        makeEnquiryModal: makeEnquiryModalReducer,
        makeEnquiryForm: makeEnquiryFormReducer,
        requestViewingModal: requestViewingModalReducer,
        listProperties: listPropertiesReducer,
        getProperty: getPropertyReducer,
        cookie: cookieReducer,
    },
})

export default store

import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import { captureException } from "@sentry/nextjs"

import axios from "axios"

const initialState = {
    status: "idle",
    errorMessage: "",
    showMakeEnquiryModal: false,
    reqType: "",
    property: "",
}

export const sendMakeEnquiryData = createAsyncThunk(
    "makeEnquiryModal/sendData",
    async (values) => {
        values.key = process.env.API_SECRET
        try {
            const response = await axios.post(
                `${process.env.API_BASE_URL}user-forms/make-enquiry/`,
                values,
            )
            if (response.status === 200) {
                return response.data
            } else {
                captureException(response.status)
                return rejectWithValue(response.status)
            }
        } catch (err) {
            captureException(err)
            return rejectWithValue(err.message)
        }
    },
)

const makeEnquiryModalSlice = createSlice({
    name: "makeEnquiryModal",
    initialState,
    reducers: {
        toggleMakeEnquiryModal: (state, action) => {
            state.showMakeEnquiryModal = action.payload
            state.reqType = ""
            state.property = ""
        },
        setMakeEnquiryReqType: (state, action) => {
            state.property = ""
            state.reqType = action.payload
        },
        setMakeEnquiryProperty: (state, action) => {
            state.property = action.payload
        },
        resetMakeEnquiryModal: (state) => {
            state.status = "idle"
        },
    },

    extraReducers(builder) {
        builder
            .addCase(sendMakeEnquiryData.pending, (state) => {
                state.status = "loading"
            })
            .addCase(sendMakeEnquiryData.fulfilled, (state) => {
                Object.assign(state, initialState)
                state.status = "success"
            })
            .addCase(sendMakeEnquiryData.rejected, (state, action) => {
                state.status = "error"
                state.errorMessage = action.payload || "No response"
            })
    },
})

export const {
    toggleMakeEnquiryModal,
    setMakeEnquiryReqType,
    setMakeEnquiryProperty,
    resetMakeEnquiryModal,
} = makeEnquiryModalSlice.actions
export default makeEnquiryModalSlice.reducer

import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import { captureException } from "@sentry/nextjs"

import axios from "axios"

const initialState = {
    status: "idle",
    errorMessage: "",
    showValuationModal: false,
    formPosition: 0,
    formData: {
        transType: "Sales Valuation",
        address: "",
        postcode: "",
        fullName: "",
        email: "",
        phone: "",
        captcha: false,
    },
}

export const sendValuationData = createAsyncThunk(
    "valuationModal/sendData",
    async (values) => {
        values.key = process.env.API_SECRET
        try {
            const response = await axios.post(
                `${process.env.API_BASE_URL}user-forms/book-valuation/`,
                values,
            )
            if (response.status === 200) {
                return response.data
            } else {
                captureException(response.status)
                return rejectWithValue(response.status)
            }
        } catch (err) {
            captureException(err)
            return rejectWithValue(err.message)
        }
    },
)

const valuationModalSlice = createSlice({
    name: "valuationModal",
    initialState,
    reducers: {
        toggleValuationModal: (state, action) => {
            state.formData.captcha = false
            state.formPosition = 0
            state.showValuationModal = action.payload
        },
        setValuationDataOne: (state, action) => {
            state.formData = action.payload
            state.formData.captcha = false
            state.formPosition = 1
        },
        setValuationDataTwo: (state, action) => {
            state.formData = action.payload
            state.formData.captcha = false
            state.formPosition = 0
        },
        resetValuationModal: (state) => {
            state.formData.captcha = false
            state.status = "idle"
        },
    },

    extraReducers(builder) {
        builder
            .addCase(sendValuationData.pending, (state) => {
                state.status = "loading"
            })
            .addCase(sendValuationData.fulfilled, (state) => {
                Object.assign(state, initialState)
                state.status = "success"
            })
            .addCase(sendValuationData.rejected, (state, action) => {
                state.status = "error"
                state.errorMessage = action.payload || "No response"
            })
    },
})

export const {
    toggleValuationModal,
    setValuationDataOne,
    setValuationDataTwo,
    resetValuationModal,
} = valuationModalSlice.actions
export default valuationModalSlice.reducer

import React from "react"
import Link from "next/link"
import Image from "next/image"
import { useRouter } from "next/router"
import { useDispatch } from "react-redux"

import { toggleValuationModal } from "@/slices/valuationModalSlice"
import { toggleFindHomeModal } from "@/slices/findHomeModalSlice"
import { Nav, Navbar, Container, Button } from "react-bootstrap"

function MainHeader({ expanded, setExpanded }) {
    const dispatch = useDispatch()

    const router = useRouter()

    const scrollToTop = () => {
        if (typeof window !== "undefined") {
            window.scrollTo(0, 0)
        }
    }

    return (
        <Container fluid className="bg-white shadow mt-0">
            <Container fluid="xxl" className="p-0 mx-auto">
                {/* Main Navbar */}
                <Navbar
                    bg="light"
                    expand="lg"
                    className=" shadow-none z-index-3 py-3"
                    expanded={expanded}
                >
                    <Navbar.Brand
                        className="p-0 d-none d-lg-inline"
                        data-placement="bottom"
                    >
                        <Link href="/">
                            <a onClick={scrollToTop()}>
                                <Image
                                    src="https://webbjenkinsstatic.imgix.net/logos/wj-full-logo.svg"
                                    alt="Webb Jenkins Logo"
                                    height={45}
                                    width={280}
                                />
                            </a>
                        </Link>
                    </Navbar.Brand>

                    <Navbar.Brand
                        className="p-0 d-none d-sm-inline d-lg-none"
                        data-placement="bottom"
                    >
                        <Link href="/">
                            <a
                                onClick={() => {
                                    scrollToTop()
                                    setExpanded(false)
                                }}
                            >
                                <Image
                                    src="https://webbjenkinsstatic.imgix.net/logos/wj-full-logo.svg"
                                    alt="Webb Jenkins Logo"
                                    height={37}
                                    width={230}
                                />
                            </a>
                        </Link>
                    </Navbar.Brand>

                    <Navbar.Brand
                        className="navbar-brand p-0 d-sm-none"
                        data-placement="bottom"
                    >
                        <Link href="/">
                            <a
                                onClick={() => {
                                    scrollToTop()
                                    setExpanded(false)
                                }}
                            >
                                <Image
                                    src="https://webbjenkinsstatic.imgix.net/logos/wj-full-logo.svg"
                                    alt="Webb Jenkins Logo"
                                    height={26}
                                    width={160}
                                />
                            </a>
                        </Link>
                    </Navbar.Brand>

                    {/* Navbar When Collapsed */}

                    <Button
                        size="lg"
                        variant="outline-warning"
                        className="px-4 ms-auto mb-0 me-2 d-none d-sm-none d-md-block d-lg-none"
                        onClick={() => dispatch(toggleFindHomeModal(true))}
                    >
                        <span className="p-0 text-wjorange text-bold">
                            Find me a home
                        </span>
                    </Button>

                    <Button
                        size="lg"
                        variant="warning"
                        className="px-3 ms-2 me-3 mb-0 d-none d-md-block d-lg-none"
                        onClick={() => dispatch(toggleValuationModal(true))}
                    >
                        <span className="p-0 text-white text-bold">
                            Get a free valuation
                        </span>
                    </Button>

                    <Button
                        size="lg"
                        variant="warning"
                        className="px-3 ms-auto me-3 mb-0 d-none d-sm-block d-md-none"
                        onClick={() => dispatch(toggleValuationModal(true))}
                    >
                        <span className="p-0 text-white text-bold">
                            Get a free valuation
                        </span>
                    </Button>

                    <Navbar.Toggle
                        className="shadow-none"
                        aria-controls="navigation"
                        onClick={() =>
                            setExpanded(expanded ? false : "expanded")
                        }
                    >
                        <span className="navbar-toggler-icon mt-2">
                            <span className="navbar-toggler-bar bar1" />
                            <span className="navbar-toggler-bar bar2" />
                            <span className="navbar-toggler-bar bar3" />
                        </span>
                    </Navbar.Toggle>
                    {/* Navbar When Collapsed End */}

                    <Navbar.Collapse
                        className="w-100 pt-3 pb-2 py-lg-0"
                        id="navigation"
                    >
                        {/* Navbar Links */}
                        <Nav
                            activeKey={router.pathname}
                            as="ul"
                            className=" topnav ms-auto me-3"
                        >
                            <Nav.Item as="li" className="mx-3 mt-2 my-lg-0">
                                <Nav.Link
                                    as={Link}
                                    href="/sellers"
                                    eventKey="/sellers"
                                    className="px-0 px-lg-1 pb-lg-0 mb-1"
                                >
                                    <a
                                        onClick={() => {
                                            scrollToTop()
                                            setExpanded(false)
                                        }}
                                    >
                                        <span
                                            className={`m-0 text-semi-bold ${
                                                router.pathname === "/sellers"
                                                    ? "active-h-link"
                                                    : ""
                                            }`}
                                        >
                                            Sellers
                                        </span>
                                    </a>
                                </Nav.Link>
                                <hr className="mt-2 d-lg-none" />
                            </Nav.Item>

                            <Nav.Item as="li" className="mx-3 mt-2 my-lg-0">
                                <Nav.Link
                                    as={Link}
                                    href="/landlords"
                                    eventKey="/landlords"
                                    className="px-0 px-lg-1 pb-lg-0 mb-1"
                                >
                                    <a
                                        onClick={() => {
                                            scrollToTop()
                                            setExpanded(false)
                                        }}
                                    >
                                        <span
                                            className={`m-0 text-semi-bold ${
                                                router.pathname === "/landlords"
                                                    ? "active-h-link"
                                                    : ""
                                            }`}
                                        >
                                            Landlords
                                        </span>
                                    </a>
                                </Nav.Link>
                                <hr className="mt-2 d-lg-none" />
                            </Nav.Item>

                            <Nav.Item
                                as="li"
                                className="mx-3 mt-2 my-lg-0 d-lg-none d-xxl-inline"
                            >
                                <Nav.Link
                                    as={Link}
                                    href="/buyers"
                                    eventKey="/buyers"
                                    className="px-0 px-lg-1 pb-lg-0 mb-1"
                                >
                                    <a
                                        onClick={() => {
                                            scrollToTop()
                                            setExpanded(false)
                                        }}
                                    >
                                        <span
                                            className={`m-0 text-semi-bold ${
                                                router.pathname === "/buyers"
                                                    ? "active-h-link"
                                                    : ""
                                            }`}
                                        >
                                            Buyers
                                        </span>
                                    </a>
                                </Nav.Link>
                                <hr className="mt-2 d-lg-none" />
                            </Nav.Item>

                            <Nav.Item
                                as="li"
                                className="mx-3 mt-2 my-lg-0 d-lg-none d-xxl-inline"
                            >
                                <Nav.Link
                                    as={Link}
                                    href="/tenants"
                                    eventKey="/tenants"
                                    className="px-0 px-lg-1 pb-lg-0 mb-1"
                                >
                                    <a
                                        onClick={() => {
                                            scrollToTop()
                                            setExpanded(false)
                                        }}
                                    >
                                        <span
                                            className={`m-0 text-semi-bold ${
                                                router.pathname === "/tenants"
                                                    ? "active-h-link"
                                                    : ""
                                            }`}
                                        >
                                            Tenants
                                        </span>
                                    </a>
                                </Nav.Link>
                                <hr className="mt-2 d-lg-none" />
                            </Nav.Item>

                            <Nav.Item as="li" className="mx-3 mt-2 my-lg-0">
                                <Nav.Link
                                    as={Link}
                                    href={{
                                        pathname: "/properties/",
                                        query: {
                                            page: 1,
                                            layout: "list",
                                            ordering: "-price",
                                        },
                                    }}
                                    eventKey="/properties"
                                    className="px-0 px-lg-1 pb-lg-0 mb-1 "
                                >
                                    <a
                                        onClick={() => {
                                            scrollToTop()
                                            setExpanded(false)
                                        }}
                                    >
                                        <span
                                            className={`m-0 text-semi-bold ${
                                                router.pathname ===
                                                "/properties"
                                                    ? "active-h-link"
                                                    : ""
                                            }`}
                                        >
                                            Properties
                                        </span>
                                    </a>
                                </Nav.Link>
                                <hr className="mt-2 d-lg-none" />
                            </Nav.Item>

                            <Nav.Item
                                as="li"
                                className="mx-3 mx-lg-0 ps-lg-3 mt-2 my-lg-0"
                            >
                                <Nav.Link
                                    as={Link}
                                    href="/property-services"
                                    eventKey="/property-services"
                                    className="px-0 px-lg-1 pb-lg-0 mb-1"
                                >
                                    <a
                                        onClick={() => {
                                            scrollToTop()
                                            setExpanded(false)
                                        }}
                                    >
                                        <span
                                            className={`m-0 text-semi-bold ${
                                                router.pathname ===
                                                "/property-services"
                                                    ? "active-h-link"
                                                    : ""
                                            }`}
                                        >
                                            Property services
                                        </span>
                                    </a>
                                </Nav.Link>
                                <hr className="mt-2 d-lg-none" />
                            </Nav.Item>

                            <Nav.Item
                                as="li"
                                className="d-md-none mx-3 mt-2 my-lg-0"
                            >
                                <Nav.Link
                                    as={Link}
                                    href="/our-team/"
                                    eventKey="/our-team/"
                                    className="px-0 px-lg-1 pb-lg-0 mb-1"
                                >
                                    <a
                                        onClick={() => {
                                            scrollToTop()
                                            setExpanded(false)
                                        }}
                                    >
                                        <span className="m-0 text-semi-bold">
                                            Our team
                                        </span>
                                    </a>
                                </Nav.Link>
                                <hr className="d-md-none mb-2 mt-2" />
                            </Nav.Item>

                            <Nav.Item as="li" className="mx-2 my-2 my-lg-0">
                                <Nav.Link
                                    as={Button}
                                    size="lg"
                                    variant="outline-warning"
                                    className="d-block col-12 d-sm-block d-md-none px-3 mb-0"
                                    onClick={() => {
                                        dispatch(toggleFindHomeModal(true))
                                        setExpanded(false)
                                    }}
                                >
                                    <span className="p-0 text-wjorange text-bold">
                                        Find me a home
                                    </span>
                                </Nav.Link>
                            </Nav.Item>

                            <Nav.Item as="li" className=" mx-2 my-2 my-lg-0">
                                <Nav.Link
                                    as={Button}
                                    size="lg"
                                    variant="warning"
                                    className="d-block d-sm-none col-12 px-3 mb-0"
                                    onClick={() => {
                                        dispatch(toggleValuationModal(true))
                                        setExpanded(false)
                                    }}
                                >
                                    <span className="p-0 text-white text-bold">
                                        Get a free valuation
                                    </span>
                                </Nav.Link>
                            </Nav.Item>
                        </Nav>
                        {/* Navbar Links End */}

                        {/* Navbar Expanded Buttons */}
                        <Nav as="ul" className=" d-xl-block d-none pe-3">
                            <Nav.Item as="li">
                                <Button
                                    size="lg"
                                    variant="outline-warning"
                                    className="px-3 px-xl-4 mb-0"
                                    onClick={() =>
                                        dispatch(toggleFindHomeModal(true))
                                    }
                                >
                                    <span className="p-0 text-wjorange text-bold">
                                        Find me a home
                                    </span>
                                </Button>
                            </Nav.Item>
                        </Nav>

                        <Nav as="ul" className="d-lg-block d-none">
                            <Nav.Item as="li">
                                <Button
                                    size="lg"
                                    variant="warning"
                                    className="px-3 px-xl-4 mb-0"
                                    onClick={() =>
                                        dispatch(toggleValuationModal(true))
                                    }
                                >
                                    <span className="p-0 text-white text-bold">
                                        Get a free valuation
                                    </span>
                                </Button>
                            </Nav.Item>
                        </Nav>
                        {/* Navbar Expanded Buttons */}
                    </Navbar.Collapse>
                </Navbar>
                {/* Main Navbar End */}
            </Container>
        </Container>
    )
}

export default MainHeader

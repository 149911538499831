import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import { captureException } from "@sentry/nextjs"

import axios from "axios"

const initialState = {
    status: "idle",
    errorMessage: "",
    showFindHomeModal: false,
    formPosition: 0,
    formData: {
        searchType: "Buy",
        houseToSell: "No",
        MinBuy: "0",
        MaxBuy: "0",
        MinRent: "0",
        MaxRent: "0",
        message: "",
        fullName: "",
        email: "",
        phone: "",
        captcha: false,
    },
}

export const sendFindHomeData = createAsyncThunk(
    "findHomeModal/sendData",
    async (values) => {
        values.key = process.env.API_SECRET
        try {
            const response = await axios.post(
                `${process.env.API_BASE_URL}user-forms/find-me-a-home/`,
                values,
            )
            if (response.status === 200) {
                return response.data
            } else {
                captureException(response.status)
                return rejectWithValue(response.status)
            }
        } catch (err) {
            captureException(err)
            return rejectWithValue(err.message)
        }
    },
)

const findHomeModalSlice = createSlice({
    name: "findHomeModal",
    initialState,
    reducers: {
        toggleFindHomeModal: (state, action) => {
            state.formData.captcha = false
            state.formPosition = 0
            state.showFindHomeModal = action.payload
        },
        setFindHomeDataOne: (state, action) => {
            state.formData = action.payload
            state.formData.captcha = false
            state.formPosition = 1
        },
        setFindHomeDataTwo: (state, action) => {
            state.formData = action.payload
            state.formData.captcha = false
            state.formPosition = 0
        },
        resetFindHomeModal: (state) => {
            state.formData.captcha = false
            state.status = "idle"
        },
    },

    extraReducers(builder) {
        builder
            .addCase(sendFindHomeData.pending, (state) => {
                state.status = "loading"
            })
            .addCase(sendFindHomeData.fulfilled, (state) => {
                Object.assign(state, initialState)
                state.status = "success"
            })
            .addCase(sendFindHomeData.rejected, (state, action) => {
                state.status = "error"
                state.errorMessage = action.payload || "No response"
            })
    },
})

export const {
    toggleFindHomeModal,
    setFindHomeDataOne,
    setFindHomeDataTwo,
    resetFindHomeModal,
} = findHomeModalSlice.actions
export default findHomeModalSlice.reducer

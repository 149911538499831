// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import { init, BrowserTracing } from "@sentry/nextjs"

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN
const API_BASE_URL = process.env.API_BASE_URL

init({
    dsn:
        SENTRY_DSN ||
        "https://1a18dcd853b9452990188da970b27e5f@o1156572.ingest.sentry.io/6237978",
    // Adjust this value in production, or use tracesSampler for greater control
    tracesSampleRate: 0.1,
    // ...
    // Note: if you want to override the automatic release value, do not set a
    // `release` value here - use the environment variable `SENTRY_RELEASE`, so
    // that it will also get attached to your source maps
    integrations: [
        new BrowserTracing({
            tracingOrigins: [API_BASE_URL],
        }),
    ],
    release: process.env.SENTRY_RELEASE,
    environment: "production",
})

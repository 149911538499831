import { createSlice } from "@reduxjs/toolkit"

const initialState = {
    cookieConsent: "false",
}

const cookieSlice = createSlice({
    name: "cookie",
    initialState,
    reducers: {
        setCookieConsent: (state, action) => {
            state.cookieConsent = action.payload
        },
    },
})

export const { setCookieConsent } = cookieSlice.actions
export default cookieSlice.reducer

import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import { captureException } from "@sentry/nextjs"

import axios from "axios"

const initialState = {
    status: "idle",
    errorMessage: "",
    showRequestViewingModal: false,
    property: "",
}

export const sendRequestViewingData = createAsyncThunk(
    "requestViewingModal/sendData",
    async (values) => {
        values.key = process.env.API_SECRET
        try {
            const response = await axios.post(
                `${process.env.API_BASE_URL}user-forms/request-viewing/`,
                values,
            )
            if (response.status === 200) {
                return response.data
            } else {
                captureException(response.status)
                return rejectWithValue(response.status)
            }
        } catch (err) {
            captureException(err)
            return rejectWithValue(err.message)
        }
    },
)

const requestViewingModalSlice = createSlice({
    name: "requestViewingModal",
    initialState,
    reducers: {
        toggleRequestViewingModal: (state, action) => {
            state.showRequestViewingModal = action.payload
            state.property = ""
        },
        setRequestViewingProperty: (state, action) => {
            state.property = action.payload
        },
        resetRequestViewingModal: (state) => {
            state.status = "idle"
        },
    },

    extraReducers(builder) {
        builder
            .addCase(sendRequestViewingData.pending, (state) => {
                state.status = "loading"
            })
            .addCase(sendRequestViewingData.fulfilled, (state) => {
                Object.assign(state, initialState)
                state.status = "success"
            })
            .addCase(sendRequestViewingData.rejected, (state, action) => {
                state.status = "error"
                state.errorMessage = action.payload || "No response"
            })
    },
})

export const {
    toggleRequestViewingModal,

    setRequestViewingProperty,
    resetRequestViewingModal,
} = requestViewingModalSlice.actions
export default requestViewingModalSlice.reducer

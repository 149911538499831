import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import { captureException } from "@sentry/nextjs"

import axios from "axios"

const initialState = {
    status: "idle",
    errorMessage: "",
}

export const sendMakeEnquiryFormData = createAsyncThunk(
    "makeEnquiryForm/sendData",
    async (values) => {
        values.key = process.env.API_SECRET
        try {
            const response = await axios.post(
                `${process.env.API_BASE_URL}user-forms/make-enquiry/`,
                values,
            )
            if (response.status === 200) {
                return response.data
            } else {
                captureException(response.status)
                return rejectWithValue(response.status)
            }
        } catch (err) {
            captureException(err)
            return rejectWithValue(err.message)
        }
    },
)

const makeEnquiryFormSlice = createSlice({
    name: "makeEnquiryForm",
    initialState,
    reducers: {
        toggleMakeEnquiryForm: (state, action) => {
            state.showMakeEnquiryForm = action.payload
        },
        resetMakeEnquiryForm: (state) => {
            state.status = "idle"
        },
    },

    extraReducers(builder) {
        builder
            .addCase(sendMakeEnquiryFormData.pending, (state) => {
                state.status = "loading"
            })
            .addCase(sendMakeEnquiryFormData.fulfilled, (state) => {
                Object.assign(state, initialState)
                state.status = "success"
            })
            .addCase(sendMakeEnquiryFormData.rejected, (state, action) => {
                state.status = "error"
                state.errorMessage = action.payload || "No response"
            })
    },
})

export const { toggleMakeEnquiryForm, resetMakeEnquiryForm } =
    makeEnquiryFormSlice.actions
export default makeEnquiryFormSlice.reducer

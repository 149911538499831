import React, { useState } from "react"

import SubHeader from "@/main/subHeader"
import MainHeader from "@/main/mainHeader"

function Header() {
    const [expanded, setExpanded] = useState(false)
    return (
        <header className="App-header sticky-top not-landscape">
            <SubHeader expanded={expanded} setExpanded={setExpanded} />
            <MainHeader expanded={expanded} setExpanded={setExpanded} />
        </header>
    )
}

export default Header

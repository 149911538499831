import React from "react"
import Head from "next/head"
import dynamic from "next/dynamic"
import Script from "next/script"
import Link from "next/link"
import { useDispatch, useSelector } from "react-redux"
import { useEffect } from "react"
import CookieConsent, { getCookieConsentValue } from "react-cookie-consent"

import { setCookieConsent } from "@/slices/cookieSlice"

import Header from "@/main/header"
const Footer = dynamic(() => import("@/main/footer"))

const GetInTouchModal = dynamic(() => import("@/forms/getInTouchModal"))
const BookValuationModal = dynamic(() => import("@/forms/bookValuationModal"))
const FindHomeModal = dynamic(() => import("@/forms/findMeAHomeModal"))
const MakeEnquiryModal = dynamic(() => import("@/forms/makeEnquiryModal"))
const RequestViewingModal = dynamic(() => import("@/forms/requestViewingModal"))

function WebsiteLayout({ children }) {
    const dispatch = useDispatch()

    useEffect(() => {
        let curConsent = getCookieConsentValue()
        if (!curConsent) {
            curConsent = "false"
        }
        dispatch(setCookieConsent(curConsent))
    }, [dispatch])

    const { cookieConsent } = useSelector((state) => state.cookie)

    return (
        <>
            <Head>
                <meta charSet="utf-8" />
                <meta
                    name="viewport"
                    content="initial-scale=1.0, width=device-width, viewport-fit=cover"
                />
            </Head>
            {cookieConsent === "true" ? (
                <>
                    <Script
                        src={`https://www.googletagmanager.com/gtag/js?id=${process.env.GOOGLE_ANALYTICS_ID}`}
                        strategy="afterInteractive"
                        nonce="ZSGO9ggYVO"
                    />
                    <Script
                        id="google-analytics-script"
                        strategy="afterInteractive"
                        nonce="836BeOwrIn"
                    >
                        {` 
                        window.dataLayer = window.dataLayer || [];
                        function gtag(){dataLayer.push(arguments);}
                        gtag('js', new Date());

                        gtag('config', '${process.env.GOOGLE_ANALYTICS_ID}', {
                            cookie_prefix: 'webbjenkins',
                            cookie_flags: 'samesite=none;secure;max_age=7200'
                        });
                        `}
                    </Script>
                </>
            ) : null}
            <BookValuationModal />
            <FindHomeModal />
            <GetInTouchModal />
            <MakeEnquiryModal />
            <RequestViewingModal />
            <Header />
            <main>{children}</main>
            <Footer />
            <CookieConsent
                containerClasses="px-3 py-3"
                location="bottom"
                buttonText="Accept"
                buttonClasses="text-white text-bold bg-warning py-2 px-5"
                buttonWrapperClasses="d-flex d-md-block justify-content-end my-auto w-100 w-md-auto"
                onAccept={() => {
                    const newConsent = getCookieConsentValue()
                    dispatch(setCookieConsent(newConsent))
                }}
            >
                <p className="my-auto text-white">
                    We use basic cookies to help to improve the browsing
                    experience on our site and analyze site traffic. Please read
                    our{" "}
                    <Link href="/privacy-policy">
                        <a>
                            <b className="text-white">
                                <u>privacy policy</u>
                            </b>
                        </a>
                    </Link>{" "}
                    and{" "}
                    <Link href="/terms-of-service">
                        <a>
                            <b className="text-white">
                                <u>terms of service</u>
                            </b>
                        </a>
                    </Link>{" "}
                    for more information. By pressing{" "}
                    <b>&lsquo;Accept&rsquo;</b>, you consent to our use of
                    cookies.
                </p>
            </CookieConsent>
        </>
    )
}

export default WebsiteLayout
